import React, { useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Img, { FluidObject } from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { useWindowSize } from 'react-use';
import NavMenu from './NavMenu';

const NavWrapper = styled.nav`
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0.5rem 1rem 1.5rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  svg {
    font-size: 2rem;
  }

  ul {
    display: none;
  }

  @media screen and (min-width: 768px) {
    padding: 1.5rem;

    svg {
      font-size: 3rem;
    }
  }

  @media screen and (min-width: 1024px) {
    svg {
      display: none;
    }
    padding: 0;
    margin: 0.5rem 4.5rem 0;
    background: none;

    &::before,
    &::after {
      content: '';
      position: fixed;
      top: 0;
      height: 95vh;
      border-right: 0.05rem solid white;
    }

    &::before {
      left: 1.12rem;
      margin-left: 2rem;
    }

    &::after {
      right: 1.12rem;
      margin-right: 2rem;
    }

    ul {
      list-style-type: none;
      font-size: 1rem;
      text-transform: uppercase;
      display: flex;
      margin-top: -1rem;

      li {
        margin: 0 1rem;

        a {
          color: white;
          text-decoration: none;
          display: inline-block;
          transition: transform 0.5s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      /* stylelint-disable */

      .active {
        a {
          padding-bottom: 0.3rem;
          border-bottom: 0.1rem solid white;
        }
      }

      /* stylelint-enable */
    }

    @media screen and (min-width: 1680px) {
      ul {
        font-size: 1.2rem;
        margin-top: -1.8rem;
      }
    }
  }
`;
const Logo = styled.div`
  width: 10rem;
  z-index: 2;

  @media screen and (min-width: 768px) {
    width: 15rem;
  }

  @media screen and (min-width: 1024px) {
    width: 12rem;
  }

  @media screen and (min-width: 1680px) {
    width: 15rem;
  }
`;
interface NavProps {
  open: boolean;
}
const NavMenuIcon = styled.div<NavProps>`
  cursor: pointer;
  transform: ${(props) => (props.open ? 'rotateY(180deg)' : 'rotateY(0deg)')};
  transition: all 0.5s ease-in-out;
  z-index: 2;
`;

const Navbar: React.FC<{ image: FluidObject }> = ({ image }) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();
  const toggleMenu = (): void => {
    setOpen(!open);
    if (open) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeMenu = (): void => {
    toggleMenu();
  };

  return (
    <NavWrapper>
      <Fade top duration={1500} delay={0}>
        <Logo>
          {width > 1024 ? (
            <a href="#home">
              <Img fluid={image} />
            </a>
          ) : (
            <AnchorLink href="#landing">
              <Img fluid={image} />
            </AnchorLink>
          )}
        </Logo>
      </Fade>
      <>
        <NavMenu open={open} closeMenu={closeMenu} />
        <NavMenuIcon open={open} onClick={toggleMenu}>
          <FaAngleDoubleLeft />
        </NavMenuIcon>
        <ul id="myMenu">
          <Fade top duration={2000} delay={0}>
            <li data-menuanchor="home">
              <a href="#home">Home</a>
            </li>
            <li data-menuanchor="courses">
              <a href="#courses">Courses</a>
            </li>
            <li data-menuanchor="why">
              <a href="#why">About us</a>
            </li>
            <li data-menuanchor="contact">
              <a href="#contact">Contact Us</a>
            </li>
          </Fade>
        </ul>
      </>
    </NavWrapper>
  );
};

export default Navbar;
