import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import SocilaIcons from './SocilaIcons';

const Menu = styled.div<{ open: boolean }>`
  background: rgba(0, 0, 0, 0.9);
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 2;
`;
const NavigationList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: space-evenly;

  a {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    color: white;
    padding: 1rem;
    display: block;
    transition: all 0.5s ease-in-out;

    svg {
      font-size: 1.9rem;
    }
  }

  a:hover,
  a:active,
  a:focus {
    transform: scale(1.3);
  }

  @media screen and (min-width: 768px) {
    a {
      letter-spacing: 0.7rem;
      font-size: 2rem;
      padding: 3rem;

      svg {
        font-size: 2.5rem;
      }
    }
  }
`;

interface Props {
  open: boolean;
  closeMenu: () => void;
}

const NavMenu: React.FC<Props> = ({ open, closeMenu }) => {
  const closeMobileMenu = (): void => {
    closeMenu();
  };
  return (
    <Menu open={open}>
      <NavigationList>
        <AnchorLink href="#landing" onClick={closeMobileMenu}>
          Home
        </AnchorLink>
        <AnchorLink href="#whatweoffer" onClick={closeMobileMenu}>
          Courses
        </AnchorLink>
        <AnchorLink href="#whymissions" onClick={closeMobileMenu}>
          About us
        </AnchorLink>
        <AnchorLink href="#contactus" onClick={closeMobileMenu}>
          Contact us
        </AnchorLink>
        <SocilaIcons />
      </NavigationList>
    </Menu>
  );
};

export default NavMenu;
