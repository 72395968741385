import React from 'react';
import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';
import useDesktop from '../../hooks/useDesktop';

const StyledTitle = styled.div`
  h2 {
    font-size: 1.71rem;
    font-weight: normal;
    margin-bottom: 2 rem;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    font-style: italic;
    line-height: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 2.8rem;
      margin-bottom: 4rem;
    }

    p {
      font-size: 1.7rem;
      line-height: 2.8rem;
    }
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 40%;
    margin-right: 2rem;

    h2 {
      font-size: 2rem;
      text-align: left;
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  @media screen and (min-width: 1280px) {
    flex: 0 0 40%;
    margin-right: 4rem;

    h2 {
      font-size: 2.6rem;
      text-align: left;
    }
  }
`;

const Download = styled.div`
  margin-top: 1.6rem;
  text-align: center;

  a {
    background: transparent;
    border: 0.1rem solid;
    border-radius: 0.5rem;
    color: white;
    display: inline-block;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    margin-top: 2.6rem;

    a {
      padding: 0.8rem 1.6rem;
      font-size: 1.3rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: 1rem;
    text-align: left;

    a {
      font-size: 1rem;
      letter-spacing: 0.05rem;
      color: white;
      padding: 0.5rem 1rem;
    }

    svg {
      font-size: 1.3rem;
    }
  }

  @media screen and (min-width: 1280px) {
    margin-top: 2rem;

    a {
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: 0.05rem;
      color: white;
    }

    svg {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 1680px) {
    a {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.05rem;
      color: white;
    }

    svg {
      font-size: 1.7rem;
    }
  }
`;

interface Props {
  heading: string;
  text: string;
  file?: string;
}

const Title: React.FC<Props> = ({ heading, text, file }) => {
  const isDesktop = useDesktop();
  return (
    <StyledTitle>
      <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 100 : 0}>
        <h2>{heading}</h2>
      </Fade>
      <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 300 : 0}>
        <p>{text}</p>
      </Fade>
      <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 600 : 0}>
        {file && (
          <Download>
            <a
              href={file}
              download="Training-Schedule"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDownload />
              Training Schedule
            </a>
          </Download>
        )}
      </Fade>
    </StyledTitle>
  );
};

export default Title;
